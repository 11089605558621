import StandardModal from '@tb-core/components/composites/standard-modal';
import BrandedLinkButton from '@tb-core/components/styled/links/branded-link-button';
import { getGlobalModal } from '@tb-core/helpers/contentful/get-global-modal';
import sessionStorage from '@tb-core/helpers/storage';
import { locationsPageUrl } from '@tb-core/helpers/urls';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import usePageContext from '@tb-core/hooks/use-page-context';

import styles from './styles.module.scss';

const modalId = 'store-needed-modal';

interface StoreNeededModalData {
    isBackShown: boolean;
}

export const useStoreNeededModal = () => {
    const { modals, updateModals } = useLayoutActivityContext();

    return {
        /**
         * Note: to prevent infinite re-render: \
         * Any attempt to close modal that is already closed is ignored
         */
        closeStoreNeededModal: () => {
            if (modals[modalId] !== undefined) {
                updateModals({ [modalId]: undefined });
            }
        },
        /**
         * Note: to prevent infinite re-render: \
         * Any attempt to open modal that is already open is ignored
         */
        openStoreNeededModal: (props: StoreNeededModalData) => {
            if (modals[modalId] === undefined) {
                updateModals({ [modalId]: props });
            }
        }
    };
};

const StoreNeededModal = () => {
    const { modals, updateModals } = useLayoutActivityContext();
    const modalData: StoreNeededModalData | undefined = modals[modalId];
    const { pageData } = usePageContext().content;
    const labels = getGlobalModal({ modalName: 'storeNeededModal', pageData });
    const onClose = () => updateModals({ [modalId]: undefined });
    const setCartAsReturnUrl = () => {
        sessionStorage.setItems({ returnToCart: true });
    };

    return modalData === undefined || labels === undefined ? (
        <></>
    ) : (
        <StandardModal
            body={
                <>
                    <h3 className={styles['modal-title']}>{labels.title}</h3>
                    <div className={styles['modal-message']}>
                        {labels.message}
                    </div>
                    <BrandedLinkButton
                        className={styles['modal-button']}
                        href={locationsPageUrl}
                        onClick={setCartAsReturnUrl}
                    >
                        {labels.buttonText}
                    </BrandedLinkButton>
                    {modalData.isBackShown && (
                        <button
                            className={styles['modal-back']}
                            onClick={onClose}
                        >
                            {labels.back}
                        </button>
                    )}
                </>
            }
            className={styles['modal']}
            contentStyle={styles['modal-body']}
            id={modalId}
            showHeader={false}
        />
    );
};

export default StoreNeededModal;
