import { KeyboardEvent } from 'react';

interface KeepFocusInElementProps {
    event: KeyboardEvent<Element>;
    id: string;
    selectorString?: string;
}
export const keepFocusInElement = ({
    event,
    id,
    selectorString = 'a[href], button:not([disabled]), textarea, input, select, [tabindex]:not([tabindex="-1"])'
}: KeepFocusInElementProps) => {
    // only execute if tab is pressed
    if (event.key !== 'Tab') return;

    // here we query all focusable elements, customize as your own need
    const focusableElements = document
        ?.getElementById(id)
        ?.querySelectorAll(selectorString);

    if (!focusableElements || focusableElements.length === 0) {
        return;
    }

    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];
    // if going forward by pressing tab and lastElement is active shift focus to first focusable element
    if (!event.shiftKey && document.activeElement === lastElement) {
        (firstElement as HTMLElement).focus();
        return event.preventDefault();
    }

    // if going backward by pressing tab and firstElement is active shift focus to last focusable element
    if (event.shiftKey && document.activeElement === firstElement) {
        (lastElement as HTMLElement).focus();
        event.preventDefault();
    }
};
