/**
 * Supports backward compatibility for short Contentful paths due to S3 asset
 * folder structure updates.
 * If '/_static/' is very first in the src string, return only the src. If it is not there,
 * return the srcPrefix + src.
 * @param srcPrefix The S3 asset source path prefix, i.e. /_static/web/icons/
 * @param src The rest of the path after the path prefix.
 */

export const s3AssetPath = (srcPrefix: string, src: string) =>
    src.indexOf('/_static/') === 0 || src.indexOf('./') === 0
        ? src
        : srcPrefix + src;
