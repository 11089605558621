export interface AddressComponent {
    [key: string]: string;
    long_name: string;
    short_name: string;
    types: [string];
}

export interface GeocodeRequestProps {
    sessiontoken?: string;
    query?: string;
}

export interface GeocodeResponseProps {
    description: string;
    matched_substring: MatchedSubString[];
    place_id: string;
    reference: string;
    structured_formatting: StructuredFormatting;
    terms: Terms[];
    types: string[];
}

interface Geometry {
    location: {
        lat: number;
        lng: number;
    };
    viewport: any;
}
export interface GeoPoint extends google.maps.LatLngLiteral {}

export interface LatLong {
    lat: number;
    long: number;
}
export interface LocationPickupMethod {
    description: string;
    icon: string;
    label: string;
    value: PickupMethod;
}

export enum PickupDetailsStep {
    FindStores = 0, // First state that should show
    PickupMethod,
    TimeMethod,
    ScheduleSelection,
    PickupDetails
}

interface MatchedSubString extends Offset {
    length: number;
}

export enum MethodType {
    Pickup = 'storePickupMethod',
    Time = 'storePickupTimeMethod'
}

interface Offset {
    offset: number;
}

/*
    PickupMethod: enum to match what is used in Back-end enum:
    list of all possible expected values as stated by Zaid Zubair Jan 26, 2022:
    
    <enumtype code="PickupMethodEnum" generate="true" autocreate="true">
        <value code="DRIVETHRU" />
        <value code="INSIDE" />
        <value code="CURBSIDE" />
        <value code="PRIORITYPICKUPLANE" />
        <value code="PICKUPSHELVES" />
        <value code="DIGITAL_ONLY" />
    </enumtype>

    Every store has 1 *inside* pickup option, and 1 *outside* pickup option.
    The *inside* pickup option is either: 'InStore' or 'PickupShelves(Grab and Go)'
    The *outside* pickup options is either: 'DriveThru' or 'DriveThruPriorityPickupLane(Mobile Pickup Lane)'
*/
export enum PickupMethod {
    DriveThru = 'DRIVETHRU',
    DriveThruPriorityPickupLane = 'PRIORITYPICKUPLANE', // aka: Mobile Pickup Lane
    InStore = 'INSIDE',
    PickupShelves = 'PICKUPSHELVES' // aka: Grab and Go
}

export type PickupMethodKeys = keyof typeof PickupMethod;

export const isPickupMethodInStore = (method: PickupMethod) => {
    switch (method) {
        case PickupMethod.DriveThru:
        case PickupMethod.DriveThruPriorityPickupLane:
            return false;
        default:
            return true;
    }
};

// If PickupMethod enum or PickupMethodIcons enum ever updated, then update Contentful as well:
// https://app.contentful.com/spaces/s2fh37axej1n/entries/1RR3x97LXCqpVPvpZh2DZd?previousEntries=5nglTus01Wzwmj7v2SmW9Z
export enum PickupMethodIcons {
    InStoreIcon = 'icon-in-store',
    DriveThruIcon = 'icon-drive-thru',
    GrabGoIcon = 'icon-grab-and-go'
}

export const pickupMethodIcons = {
    [PickupMethod.DriveThru]: PickupMethodIcons.DriveThruIcon,
    [PickupMethod.DriveThruPriorityPickupLane]: PickupMethodIcons.DriveThruIcon,
    [PickupMethod.InStore]: PickupMethodIcons.InStoreIcon,
    [PickupMethod.PickupShelves]: PickupMethodIcons.GrabGoIcon
};

/**
 * TimeSlot Type, an array of these is returned from getStorePickupTimes()
 *
 * Other Properties that are returned that are not of use:
 * -------------------------------------------------------
 * curbSideAvailable: boolean; // Don't care because stores no longer do curbSide
 * date: string; // "2022-03-31T17:00:00+0000" // do not use : timezone offset wrong, always: '+0000'
 * displayDateTitle: string; // "Thursday, March 31"
 * wholeHour: boolean; // 'true' if zero minutes like 5:00, else 'false' - like 5:30
 * titleIfNotAvailable: string; // "All pickup methods are not available at this time."
 */
export interface TimeSlot {
    driveThruAvailable: boolean;
    formattedTime: string; // "5:00 PM"
    inStoreAvailable: boolean;
    timestamp: number; // 1648746000000
}
interface Photo {
    height: number;
    html_attributes: string[];
    photo_reference: string;
    width: string;
}

export interface PlaceDetailsRequestProps {
    place?: string;
    input?: {
        location: LatLong[];
    };
}

export interface PlaceDetailsResponseProps {
    address_components: AddressComponent[];
    adr_address?: string;
    formatted_address: string;
    geometry: Geometry;
    icon?: string;
    name?: string;
    photos?: Photo[];
    place_id: string;
    reference?: string;
    types: string[];
    url?: string;
    utc_offset?: number;
    vicinity?: string;
    website?: string;
}

export interface PlaceIdDetailsRequestProps {
    place_id: string;
    sessiontoken: string;
}

export interface PostPlacesResponseProps {
    boundEastLongitude: number;
    boundNorthLatitude: number;
    boundSouthLatitude: number;
    boundWestLongitude: number;
    cartIsEmpty: boolean;
    favoriteStores: []; // @TODO provide type for favoriteStores
    nearByStores: []; // @TODO provide type for nearByStores
    sourceLatitude: number;
    sourceLongitude: number;
}

export type SetLocationStateDispatch = React.Dispatch<
    React.SetStateAction<PickupDetailsStep>
>;

export enum TimeMethod {
    Later = 'LATER',
    Now = 'NOW'
}

interface StructuredFormatting {
    main_text: string;
    main_text_matched_substrings: MatchedSubString;
    secondary_text: string;
}

interface Terms extends Offset {
    value: string;
}
