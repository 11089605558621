import { useContext } from 'react';

// @TODO Update to core - current core page context provider will need to be deprecated.
import { UserContext } from '@tb-core/components/context/user';

const useUserContext = () => {
    const ctx = useContext(UserContext);

    if (ctx === undefined) {
        throw new Error('useUserContext must be used within a UserProvider');
    }

    return ctx;
};

export default useUserContext;
