import { deleteCookie, setCookies } from '@tb-core/helpers/document-cookie';

/**
 * Deletes store_id cookie then resets the cookie to avoid having
 * multiple store_id cookies set.
 * @param storeId The store id you want to set in cookies
 */
export const setStoreIdCookie = (storeId: number | string) => {
    deleteCookie('store_id');
    setCookies({ store_id: storeId }, 365, '/');
};
