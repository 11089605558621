import classNames from 'classnames';
import { CSSProperties } from 'react';

import SafeAnchorWithGaEvent, {
    SafeAnchorWithGaEventProps
} from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import ThemedColor, { ColorTheme } from '@tb-core/components/themed/color';

import styles from './styles.module.scss';

export interface ThemableLinkButtonProps extends SafeAnchorWithGaEventProps {
    border?: boolean;
    colorTheme?: CSSProperties;
    inverse?: boolean;
    isLoading?: boolean;
}

// This component will render the same look as a primary button
const ThemableLinkButton = ({
    border = true,
    children,
    className,
    colorTheme,
    inverse = false,
    isLoading = false,
    theme,
    ...props
}: ThemableLinkButtonProps & { theme?: ColorTheme }) => (
    <ThemedColor theme={theme}>
        {({ bgColor, color }) => (
            <SafeAnchorWithGaEvent
                {...props}
                className={classNames(
                    styles['link-button'],
                    {
                        [styles['no-border']]: !border,
                        [styles.inverse]: inverse,
                        [bgColor]: !inverse,
                        [color]: inverse
                    },
                    className
                )}
                style={colorTheme}
            >
                {isLoading ? (
                    <div className={styles['dot-flashing']}></div>
                ) : (
                    children
                )}
            </SafeAnchorWithGaEvent>
        )}
    </ThemedColor>
);

export default ThemableLinkButton;
