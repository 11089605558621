export enum ProductTypes {
    'BYOB' = 'byob',
    'COMBO' = 'combo',
    'DRINK' = 'drink',
    'PARTYPACK' = 'partypack'
}

/**
 * Product Ignore List
 * SLUG to PLU ENUM
 * both KEYs and VALUEs are used on PDP and CLP respectively
 * additional test values:
 *    'crunchy-taco' = '22100',
 *    'soft-taco' = '22110'
 */
export enum ProductIgnoreList {
    'taco-lovers-pass' = '550516'
}

export enum ProductImageSize {
    _269_X_269 = '269x269',
    _750_X_340 = '750x340',
    _750_X_610 = '750x610',
    _750_X_660 = '750x660'
}

export enum KitchenMessages {
    NOUTENSILS = '550204'
}
