import { PageDataProps } from '@tb-core/adapters/contentful/graphql/webpage-adapter';
import { RealObject } from '@tb-core/types';

/**
 * Extracts out text strings from Contentful for Global Modals \
 * Contentful Global Modals are TopicMeta data of the Standard Layout Full:
 * https://app.contentful.com/spaces/s2fh37axej1n/entries/2DOStQmPNIGnbVjXdUb1Or
 */
export const getGlobalModal = ({
    modalName,
    pageData
}: {
    modalName: string;
    pageData: Omit<PageDataProps, 'topicMetadataCollection'>;
}) => {
    const contentfulItems = pageData.layout.topicMetadataCollection.items;
    const labels: RealObject<string> | undefined = contentfulItems.find(
        item => item.topic === 'Generic'
    )?.data?.[modalName];
    return labels;
};
