import classNames from 'classnames';

import withNextConfig from '@tb-core/components/hoc/with-next-config';
import { s3AssetPath } from '@tb-core/helpers/contentful/s3-asset-path';
import { assetPath } from '@tb-core/helpers/next-env';

import styles from './styles.module.scss';

export interface ImageProps {
    alt?: string;
    assetPath?: string;
    className?: string;
    src: string | null;
    tabIndex?: number;
    tabIndexProp?: number;
    title?: string;
}

// Using a key fixes this bug:
// https://stackoverflow.com/questions/50690956/react-img-not-immediately-changing-when-src-changes-how-can-i-fix-this
const Image = ({
    alt,
    className,
    src = null,
    tabIndexProp = -1,
    title
}: ImageProps) => (
    <>
        {src && (
            <img
                alt={alt}
                className={classNames(styles.image, className)}
                key={src}
                src={
                    src.indexOf('http') !== -1
                        ? src
                        : assetPath + s3AssetPath('/_static/images/', src || '')
                }
                tabIndex={tabIndexProp}
                title={title}
            />
        )}
    </>
);

export default withNextConfig(Image);
