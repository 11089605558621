import crypto from 'crypto';

import IngredientsAdapter from '@tb-core/adapters/products/ingredients-adapter';
import ProteinOptionsAdapter from '@tb-core/adapters/products/protein-options-adapter';
import { IngredientCardProps } from '@tb-core/components/composites/products/ingredient-card';
import {
    CSCItemModifierOptions,
    CSCItemModifierType
} from '@tb-core/constants/client-side-cart';
import { KitchenMessages } from '@tb-core/constants/products';
import { calculateIncludedCalorieDifference } from '@tb-core/helpers/products/calories';
import { LocalCart } from '@tb-core/hooks/client-side-cart/use-cart';
import { Option } from '@tb-core/hooks/product/use-customize';
import { RealObject } from '@tb-core/types';
import {
    CSCItem,
    CSCItemDetail,
    CSCItemModifier,
    CSCItemTemplate,
    CSCModifierDetail
} from '@tb-core/types/client-side-cart';
import {
    CustomizationOption,
    CustomizationOptions,
    Price,
    Product,
    StyleOption,
    VariantOption
} from '@tb-core/types/products';

// these functions help to determine whether an option category is
// a style option or customization option
export const isStyleOption = (object: StyleOption) => {
    return object?.primaryCategoryCode === 'styles';
};

export const isCustomizationOption = (object: CustomizationOption[]) => {
    return object[0]?.variantOptions !== undefined;
};

/**
 *
 * @param str Creates a sha256 hash based on a passed string
 * @returns
 */
export const create256Hash = (str: string) =>
    crypto
        .createHash('sha256')
        .update(str)
        .digest('hex');

export const createModifierString = (modifiers: CSCItemModifier[]) =>
    modifiers
        .map(
            (modifier: RealObject) => modifier.opt + modifier.ots + modifier.plu
        )
        .join('');

/**
 *
 * @param modType string value of modifier type.
 * @param onTheSide boolean value determining if item should be on the side
 * @returns
 */
export const getModifierSuffix = (
    modType: string,
    onTheSide: boolean,
    onTheSideLabel?: string
) => {
    if (modType?.match(/^EASY|EXTRA$/)) {
        return ` (${modType.toLowerCase()})`;
    } else if (onTheSide) {
        return ` ${onTheSideLabel}`;
    }
    return '';
};

/**
 * sort modifiers by name, then on the side value, then plu
 */
export const sortModifiers = (modifiers: any) =>
    modifiers.sort((a: RealObject, b: RealObject) => {
        if (a.opt > b.opt) {
            return 1;
        } else if (a.opt < b.opt) {
            return -1;
        }
        if (a.ots > b.ots) {
            return 1;
        } else if (a.ots < b.ots) {
            return -1;
        }
        if (a.plu > b.plu) {
            return 1;
        } else if (a.plu < b.plu) {
            return -1;
        }
        return 0;
    });

const createModifierData = (
    isOnTheSide: boolean,
    includedWithStyle: boolean,
    opt: CSCItemModifierOptions,
    variantOption: VariantOption | IngredientCardProps
) => {
    const getOptionPrice = () => {
        if (
            (opt === CSCItemModifierOptions.PROTEIN ||
                opt === CSCItemModifierOptions.INCLUDE) &&
            'price' in variantOption
        ) {
            return variantOption.price;
        } else if (
            'priceData' in variantOption &&
            typeof variantOption.priceData?.value !== 'undefined'
        ) {
            return variantOption.priceData?.value;
        }
        return 0;
    };

    const adaptedModifierData: Option = {
        accurateCalorie: variantOption.accurateCalorie,
        calories: variantOption.calories,
        code: variantOption.code,
        includedWithStyle,
        opt,
        price: getOptionPrice()
    };

    if (isOnTheSide && 'onTheSide' in variantOption) {
        adaptedModifierData.onTheSide = variantOption.onTheSide;
    }

    return adaptedModifierData;
};

/**
 * This function helps adapt the modifiers received from the cart into a data structure of type 'Option'
 * which will be used by the 'selectedOptions' property from the 'useCustomize' hook
 *
 * @param customizationOptions - the customizationOptions from the product JSON
 * @param modifiers - the modifiers received from the cart
 * @return adaptedModifiers - an array of objects of type 'Option' that contain the modifiers along
 *                            with any additional information needed by the 'useCustomize' hook
 */
export const cartModifiersAdapter = (
    customizationOptions: CustomizationOptions,
    modifiers: CSCItemModifier[]
) => {
    const adaptedModifiers: Option[] = [];
    const adaptedModifiersCodes: string[] = [];
    const customizationOption = 'Option';
    const styleOption = 'Product';

    modifiers?.forEach(modifierItem => {
        if (
            [
                CSCItemModifierOptions.PROTEIN,
                CSCItemModifierOptions.INCLUDE
            ].includes(modifierItem.opt)
        ) {
            const selectedCustomizationOptions =
                modifierItem.opt === CSCItemModifierOptions.PROTEIN
                    ? customizationOptions.proteinOptions
                    : customizationOptions.includeOptions;
            const options = IngredientsAdapter(
                ProteinOptionsAdapter(selectedCustomizationOptions)
            );
            options.forEach(option => {
                if (
                    option.code === modifierItem.plu &&
                    !adaptedModifiersCodes.includes(option.code)
                ) {
                    let { calories, accurateCalorie } = option;
                    let matchedCustomizationOption;

                    // If modifier is not the main variant in the Included options,
                    // calculate the calorie difference for the modifier.
                    // Ex:
                    // Cheese = 30 calories
                    // MINUS Cheese = 0 calories
                    // MINUS modifier calories = 0 - 30 = -30
                    if (option.modifier !== '') {
                        matchedCustomizationOption = selectedCustomizationOptions.find(
                            o =>
                                o.variantOptions.find(
                                    o2 => o2.code === option.code
                                )
                        );
                    } else if (!option.defaultProtein) {
                        // If modifier is not the defaultProtein in the Protein options,
                        // calculate the calorie difference for the modifier.
                        matchedCustomizationOption = selectedCustomizationOptions.find(
                            o => o.defaultProtein
                        );
                    }
                    if (matchedCustomizationOption) {
                        const {
                            accurateCalorieDiff,
                            caloriesDiff
                        } = calculateIncludedCalorieDifference(
                            accurateCalorie,
                            calories,
                            matchedCustomizationOption
                        );
                        calories = caloriesDiff;
                        accurateCalorie = accurateCalorieDiff;
                    }

                    adaptedModifiersCodes.push(option.code);
                    adaptedModifiers.push(
                        createModifierData(
                            false,
                            modifierItem.includedWithStyle,
                            modifierItem.opt,
                            {
                                ...option,
                                accurateCalorie,
                                calories
                            }
                        )
                    );
                }
            });
        }

        // grab the opt category then pull the variantOptions from it
        if (modifierItem.opt !== CSCItemModifierOptions.NOT_SELECTED) {
            const optionCategory = customizationOptions[modifierItem.opt];
            let isOnTheSide = false;

            // check wether the modifier is a style card or not
            if (
                optionCategory &&
                modifierItem.opt.includes(customizationOption)
            ) {
                (optionCategory as CustomizationOption[]).forEach(
                    (optionItem: CustomizationOption) => {
                        // modifier is on the side
                        if (optionItem.code && modifierItem.ots) {
                            // can assume modifier type is ADD since it is on the side
                            const variantOption = optionItem.variantOptions.find(
                                variant =>
                                    variant.onTheSide === modifierItem.plu &&
                                    variant.modifierType ===
                                        CSCItemModifierType.ADD
                            );

                            if (
                                variantOption &&
                                !adaptedModifiersCodes.includes(
                                    variantOption.code
                                )
                            ) {
                                isOnTheSide = true;

                                adaptedModifiersCodes.push(variantOption.code);
                                adaptedModifiers.push(
                                    createModifierData(
                                        isOnTheSide,
                                        modifierItem.includedWithStyle,
                                        modifierItem.opt,
                                        variantOption
                                    )
                                );
                            }
                        } else if (
                            optionItem.code &&
                            optionItem.code.includes(modifierItem.plu)
                        ) {
                            const variantOption = optionItem.variantOptions.find(
                                variant => variant.code === modifierItem.plu
                            );

                            if (
                                variantOption &&
                                !adaptedModifiersCodes.includes(
                                    variantOption.code
                                )
                            ) {
                                adaptedModifiersCodes.push(variantOption.code);
                                adaptedModifiers.push(
                                    createModifierData(
                                        isOnTheSide,
                                        modifierItem.includedWithStyle,
                                        modifierItem.opt,
                                        variantOption
                                    )
                                );
                            }
                        }
                    }
                );
            } else if (
                optionCategory &&
                modifierItem.opt.includes(styleOption)
            ) {
                // style option
                const variantOption: VariantOption = (optionCategory as StyleOption)
                    .variantOptions[0];

                if (
                    variantOption &&
                    !adaptedModifiersCodes.includes(variantOption.code)
                ) {
                    // Adjust styles to use baseProduct code, rather than variant.
                    variantOption.code = modifierItem.plu;
                    adaptedModifiersCodes.push(variantOption.code);
                    adaptedModifiers.push(
                        createModifierData(
                            isOnTheSide,
                            modifierItem.includedWithStyle,
                            modifierItem.opt,
                            variantOption
                        )
                    );
                }
            }
        }
    });

    return adaptedModifiers;
};

export const getTotalCartQuantity = (cart: CSCItem[]) => {
    const totalCartQuantity = cart.reduce(
        (totalQuantity, cartItemQuantity) =>
            totalQuantity +
            (cartItemQuantity.plu === KitchenMessages.NOUTENSILS
                ? 0
                : cartItemQuantity.qty || 0),
        0
    );
    return totalCartQuantity;
};

// get base price and combine with any modifiers, items, and item modifiers
export const getProductPrice = (
    baseProduct: Product,
    hhPrice: Price | undefined,
    isHH: boolean,
    items?: CSCItemDetail[],
    modifiers?: CSCModifierDetail[]
) => {
    const basePrice =
        isHH && hhPrice ? hhPrice.value : baseProduct.price?.value || 0;

    let itemModifiersPrices = 0;
    let itemPrice = 0;
    let modifierPrice = 0;

    if (items) {
        items.forEach((item: CSCItem) => {
            const modifierQty = item.qty || 1;

            return item.modifiers?.forEach(
                ({ includedWithStyle, price = 0 }: CSCModifierDetail) =>
                    (itemModifiersPrices +=
                        (includedWithStyle ? 0 : price) * modifierQty)
            );
        });

        items.forEach(
            ({ price, qty }: CSCItemDetail) => (itemPrice += price * (qty || 1))
        );
    }

    if (modifiers) {
        modifiers.forEach(
            ({ includedWithStyle, price = 0 }: CSCModifierDetail) =>
                (modifierPrice += includedWithStyle ? 0 : price)
        );
    }

    return basePrice + itemPrice + itemModifiersPrices + modifierPrice;
};

export const getProductSubtotal = (
    products: CSCItemTemplate[] = [],
    includeUnavailable: boolean = false
) => {
    let subtotal = 0;
    products.forEach(product => {
        // Do not include Unavailable items in total unless specified.
        // Cart icon subtotal should include all product prices on add.
        if (
            includeUnavailable ||
            (product.isAvailable && !product.isOutsideBreakfastTimeItem)
        ) {
            subtotal += ((product.price || 0) * 100 * product.qty) / 100;
        }
    });
    return subtotal;
};

/**
 * This function compares the products to pdpProductsJson and if there is an item in products
 * that is not in pdpProductsJson, then we delete from indexDB
 *
 * @param products - products from local cart
 * @param pdpProductsJson - products from nextProductUrl
 * @param local the current local cart in storage
 * @return boolean
 */
export const deleteProductFromCart = (
    products: CSCItem[],
    pdpProductsJson: Product[],
    local: LocalCart
) => {
    products.forEach(product => {
        let itemsWereDeleted = false;
        const productIsAvailable = pdpProductsJson.some(
            pdpProduct => pdpProduct.code === product.plu
        );

        if (product?.items) {
            const comboChildItemsAreAvailable = product.items.every(item =>
                pdpProductsJson.some(pdpProduct => pdpProduct.code === item.plu)
            );

            if (
                (!productIsAvailable || !comboChildItemsAreAvailable) &&
                local.deleteCartItem
            ) {
                local.deleteCartItem({
                    id: product.id,
                    plu: product.plu
                });

                itemsWereDeleted = true;
            }
        } else {
            if (!productIsAvailable && local.deleteCartItem) {
                local.deleteCartItem({
                    id: product.id,
                    plu: product.plu
                });

                itemsWereDeleted = true;
            }
        }

        return itemsWereDeleted;
    });
};
