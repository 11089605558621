import { RealObject } from '@tb-core/types';

/**
 * Interpolates placeholders from a string into some specified values -
 * placeholder shall be wrapped in single curly braces & named after some
 * model property - model is provided to replace the placeholders with
 * values.
 *
 * @param str {string} :The placeholder-inclusive string to interpolate.
 * @param model {object} :The model of values to replace the placeholders
 *      with.
 *
 * @return {string} :The newly interpolated string.
 */
export function interpolate<T = RealObject<number | string>>(
    str: string,
    model: T
): string {
    const interpolated = str.replace(
        /{([^{}]*)}/g,
        (_interpolation, key: string) => {
            const value = (model as RealObject)[key.replace(/^\s+|\s+$/gm, '')];
            return typeof value === 'string' || typeof value === 'number'
                ? String(value)
                : '';
        }
    );
    return interpolated;
}
