import { RealObject } from '@tb-core/types';
import ReactGA from 'react-ga';

export type GaDataLayerConfig = RealObject<
    string | number | null | boolean | undefined | RealObject
>;
export type WindowDataLayer = Window &
    typeof globalThis & {
        dataLayer?: GaDataLayerConfig[];
    };
export interface UpdateAndClearClickEventProps {
    page: string;
    value: string;
}
export interface DataLayerObject extends GaDataLayerConfig {
    currentStore: string;
    pageType?: string;
    visitorId?: string;
    visitorStatus?: string;
}

let previousDataLayerPush: GaDataLayerConfig; // Holds custom data from previous data layer push

/**
 * Updates the data layer with events or custom data.
 * @param data The config to add to the dataLayer.
 *  https://developers.google.com/tag-manager/devguide#events
 *  https://developers.google.com/tag-manager/devguide#multipush
 * @example
 *      const onClickHandler = () => updateDataLayer({ event: 'event-name'});
 *      return (
 *          <button onClick={onClickHandler}>Track my click!</button>
 *      );
 * @example
 *      // when omitting the "event" key/value entry...
 *      updateDataLayer({
 *          'Analytics-Action': 'Left Nav',
 *          'Analytics-Value': 'success!'
 *      })
 *      // results in "Message" w/ dataLayer "{Analytics-Action: 'Left Nav', Analytics-Value: 'success!'}"
 */
export const updateDataLayer = (data: GaDataLayerConfig) => {
    // Set any custom keys that may be persisting from the previous push to undefined
    for (const key in previousDataLayerPush) {
        if (
            !data.hasOwnProperty(key) &&
            !key.includes('event') &&
            !key.includes('gtm.')
        ) {
            data[key] = undefined;
        }
    }

    previousDataLayerPush = data; // Update the object with the new data being pushed

    (window as WindowDataLayer).dataLayer?.push(data);
};

/*
 * Pushes a click event with 'Analytic-Action' set to the page and 'Analytics-Value' as the value provided.
 * After pushing to the dataLayer, an object with 'Analytics-Action' and 'Analytics-Value' will be pushed with the values set to
 * undefined. Helpful when multiple dataLayer pushes happen in a single page. Prevents subsequent dataLayer pushes
 * from inadvertently including 'Analytics-Action' and 'Analytics-Value' unless explicitly included.
 */
export const updateAndClearClickEvent = ({
    page,
    value
}: UpdateAndClearClickEventProps) => {
    updateDataLayer({
        'Analytics-Action': page,
        'Analytics-Value': value,
        event: 'gtm.click'
    });

    // Need to mark 'Analytics-Value' and 'Analytics-Action' as undefined to prevent
    // them from appearing in the next dataLayer update
    updateDataLayer({
        'Analytics-Action': undefined,
        'Analytics-Value': undefined
    });
};

/*
 * Similar to the function above, however this will take all arguements being passed
 * to it and clear them as well with a subsequent push to the data layer.
 */
export const updateDataLayerAndClear = (data: GaDataLayerConfig) => {
    // Takes the data argument object and creates a new object with the same keys
    // with an undefined value
    const undefinedObjValues: GaDataLayerConfig = Object.keys(data).reduce(
        (acc, key) => {
            return {
                ...acc,
                [key]: undefined
            };
        },
        {}
    );
    updateDataLayer(data);

    updateDataLayer(undefinedObjValues);
};

/* [DEPRECATED] React GA */

type ReactGAWindow = Window & { gaInitialized?: boolean };

interface GATracker {
    gaOptions: { name: string };
    trackingId: string;
}

export function initGA(gaTrackers: GATracker[]) {
    const win: ReactGAWindow = window;
    if (win && !win.gaInitialized) {
        ReactGA.initialize(gaTrackers);
        win.gaInitialized = true;
    }
}

export function logPageView(trackerNames: string[]) {
    ReactGA.set({ page: window.location.pathname }, trackerNames);
    ReactGA.pageview(window.location.pathname, trackerNames);
}

export function logEvent(category = '', action = '', label = '') {
    if (category && action) {
        ReactGA.event({ category, action, label });
    }
}

export function gtmLinkClick(data: GaDataLayerConfig) {
    updateDataLayer({
        event: 'gtm.linkClick',
        ...data
    });
}
