import Fetch from '@tb-core/helpers/fetch';
import { logoutUrl } from '@tb-core/next/api/urls';
import { CSCResponseBody } from '@tb-core/types/client-side-cart';

// POST call to send an API request through the Next server,
// and delete a user's logged-in cookies.
export const postDeleteAuthCookies = async () => {
    let res: Response;

    try {
        res = await Fetch({
            host: '',
            url: logoutUrl
        });
    } catch (e) {
        console.error('Logout API POST request failed!', e);
        return {} as CSCResponseBody;
    }

    if (!res.ok) {
        return {} as CSCResponseBody;
    }

    return;
};
