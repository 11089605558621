import classNames from 'classnames';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';

import styles from './styles.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    className?: string;
}

const Button = ({ children, className, ...props }: ButtonProps) => (
    <button {...props} className={classNames(styles.button, className)}>
        {children}
    </button>
);

export default Button;
