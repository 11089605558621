import { RealObject } from '@tb-core/types';
/**
 * Client-side only.
 * Iterates cookies to set each one to Document.Cookie with expiration.
 * @param cookies An object containing entries as key/value pairs.
 * @param daysToExpire An EMPTY STRING will store during session; ZERO will expire the cookie.
 */
export function setCookies(
    cookies: RealObject,
    daysToExpire?: number,
    path?: string
) {
    let expires = '';

    // If the expiration days argument is provided and greater than 0 days, set the expiration date.
    // Otherwise, leave it blank so that "SESSION" is used, saving the cookie for only the current session.
    if (daysToExpire || daysToExpire === 0) {
        const date = new Date();

        date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
        expires = ' expires=' + date.toUTCString();
    }

    Object.entries(cookies).forEach(
        ([key, value]) =>
            (window.document.cookie = `${key}=${value};${expires}; ${
                path ? `path=${path}` : null
            }`)
    );
}

/**
 * Client-side only.
 * Returns the value of the desired cookie from Document.Cookie, or null.
 * @param key The name of the cookie.
 */
export function getDocumentCookie(key: string) {
    const cookieRe = RegExp(`${key}=([^;]*)`);
    const match = window.document.cookie.match(cookieRe);

    return match && match[1];
}

/**
 * Client-side only.
 * Deletes cookie by setting expire date into the past.
 * @param key The name of the cookie.
 */
export function deleteCookie(key: string) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
