import { useContext } from 'react';

// @TODO Update to core - current core page context provider will need to be deprecated.
import { LayoutActivityContext } from '@tb-core/components/context/layout-activity';

const useLayoutActivityContext = () => {
    const ctx = useContext(LayoutActivityContext);

    if (ctx === undefined) {
        throw new Error(
            'useLayoutActivityContext must be used within a LayoutActivityProvider'
        );
    }

    return ctx;
};

export default useLayoutActivityContext;
