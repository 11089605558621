import LazyLoadSvg from '@tb-core/components/composites/lazyload/svg';
import { SvgProps } from '@tb-core/components/simple/svg';

export interface LazyIconCloseProps extends Omit<SvgProps, 'svgId'> {}

const LazyIconClose = (props: LazyIconCloseProps) => {
    return <LazyLoadSvg {...props} svgId="icon-close" />;
};

export default LazyIconClose;
