import { createInstance } from '@optimizely/react-sdk';

import { optimizelySdkKey } from '@tb-core/helpers/next-env';

const logger = process.env.ENV_ID === 'rel2-q' ? 'info' : 'null';

export const optimizelyClient = createInstance({
    logLevel: logger,
    sdkKey: optimizelySdkKey
});

export const getOptimizelyExperiments = () => {
    const config = optimizelyClient.getOptimizelyConfig();

    if (config) {
        const experiments = Object.values(config.experimentsMap).map(exp => {
            const expKeys = Object.values(exp.variationsMap);

            let variation = optimizelyClient.getVariation(exp.key);

            const checkVariation = () => {
                for (const variant of expKeys) {
                    if (variant.featureEnabled) {
                        return (variation = variant.key);
                    }
                }
            };

            return {
                experimentKey: exp.key,
                variationKey: variation !== null ? variation : checkVariation()
            };
        });

        return experiments;
    }
};
