import { Store } from '@tb-core/types';
import { StoreResponse } from '@tb-core/types/stores';

/**
 * Adapts the store response to the Store interface.
 * @param param0 The store response.
 */
export const storeAdapter: (response: StoreResponse) => Store = ({
    address,
    storeNumber,
    timeZone
}) => ({
    storeAddress: address?.line1,
    storeCity: address?.town,
    storeId: storeNumber,
    storePinCode: address?.postalCode,
    storeState: address?.region.isocode.replace(
        `${address?.country.isocode}-`,
        ''
    ),
    timeZone
});
