import React from 'react';

export interface SvgProps {
    className?: string;
    desc?: string;
    height?: number | string;
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    size?: number | string;
    svgId: string;
    tabIndex?: number;
    title?: string;
    width?: number | string;
}

const Svg = ({
    className = '',
    desc,
    height = '100%',
    onClick,
    size,
    svgId,
    title,
    width = '100%'
}: SvgProps) => (
    <svg
        aria-labelledby={`title-${svgId} desc-${svgId}`}
        className={className}
        fill="currentColor"
        height={size || height}
        onClick={onClick}
        width={size || width}
    >
        {title && <title id={`title-${svgId}`}>{title}</title>}
        {desc && <desc id={`desc-${svgId}`}>{desc || svgId}</desc>}
        <use xlinkHref={`#${svgId}`}></use>
    </svg>
);

export default Svg;
