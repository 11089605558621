import { CustomizationOption, ModifierType } from '@tb-core/types/products';

export const getVariant = (
    option: CustomizationOption,
    modifierType: ModifierType
) =>
    option.variantOptions?.find(
        variantOption =>
            variantOption?.modifierType.toLowerCase() ===
            modifierType.toLowerCase()
    );
