import { createContext, ReactNode, useState } from 'react';

import { Props } from '@tb-core/types';

interface CartTooltipProviderProps {
    children: ReactNode;
}

const CartTooltipContext = createContext<Props | undefined>(undefined);
const { Consumer, Provider } = CartTooltipContext;

const CartTooltipProvider = ({ children }: CartTooltipProviderProps) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [showMiniCart, setShowMiniCart] = useState<boolean>(false);
    const [miniCartHeaderText, setMiniCartHeaderText] = useState<string>('');
    const [isCartLoading, setIsCartLoading] = useState<boolean>(false);

    return (
        <Provider
            value={{
                isCartLoading,
                miniCartHeaderText,
                setIsCartLoading,
                setMiniCartHeaderText,
                setShowMiniCart,
                setShowTooltip,
                showMiniCart,
                showTooltip
            }}
        >
            {children}
        </Provider>
    );
};

const CartTooltipConsumer = Consumer;

export default CartTooltipProvider;
export { CartTooltipConsumer, CartTooltipContext };
