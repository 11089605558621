import LazyLoad from 'react-lazyload';

import Svg, { SvgProps } from '@tb-core/components/simple/svg';

const LazyLoadSvg = ({ height = '100%', ...props }: SvgProps) => (
    <LazyLoad height={height} scrollContainer={'#scrollContainer'}>
        <Svg {...props} height={height} />
    </LazyLoad>
);

export default LazyLoadSvg;
