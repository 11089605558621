import { datadogLogs } from '@datadog/browser-logs';
import {
    BaseTokenRequestHandler,
    FetchRequestor,
    GRANT_TYPE_REFRESH_TOKEN,
    TokenRequest,
    TokenRequestJson,
    TokenResponse
} from '@openid/appauth';

import { TokenGrantType } from '@tb-core/constants/auth';
import { devProxyResolve } from '@tb-core/helpers/browser/dev-proxy-resolve';
import { parseJwt } from '@tb-core/helpers/client/auth';
import { getDecodedCookie } from '@tb-core/helpers/cookies';
import Fetch from '@tb-core/helpers/fetch';
import {
    webOrigin,
    yumOidcClientIdPasswordless
} from '@tb-core/helpers/next-env';
import { arrangeMagicLinkUrl, getCustomerUrl } from '@tb-core/next/api/urls';
import {
    fetchAuthConfig,
    oAuthConfigSettings
} from '@tb-core/providers/auth/fetch-auth-config';
import { postDeleteAuthCookies } from '@tb-core/providers/post-delete-auth-cookies';
import { RealObject } from '@tb-core/types';

interface AuthRequestProps {
    birthday?: string;
    credential: string;
    email: string;
    first_name?: string;
    last_name?: string;
    newsletter?: boolean;
    tos?: boolean;
}

export interface UserAdapterTokenResponse {
    access_token: string;
    expires_in: number;
    id_token?: string;
    refresh_token: string;
    scope: string;
    token_type: string;
}
/**
 * Make an OAuth token request. Request can be access_token,
 * refresh_token, or guest_token.
 *
 * @param requestSettings - OpenID token request settings
 * @param redirectUrl - URL to redirect the user to after a successful token request
 */
export const makeTokenRequest = async (
    requestSettings: Omit<TokenRequestJson, 'client_id' | 'redirect_uri'>,
    redirectUrl = ''
) => {
    let tokenRes: TokenResponse;
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());

    try {
        const config = await fetchAuthConfig();

        const performTokenRequest = async (clientId: string) => {
            const tokenRequestBody = {
                ...requestSettings,
                client_id: clientId,
                redirect_uri: webOrigin + redirectUrl
            };

            const tokenRequest = new TokenRequest(tokenRequestBody);
            return await tokenHandler.performTokenRequest(config, tokenRequest);
        };

        if (config) {
            // If we are refreshing the token, we need to make the call with
            // both client ids in case the first one fails.
            if (requestSettings.grant_type === GRANT_TYPE_REFRESH_TOKEN) {
                try {
                    tokenRes = await performTokenRequest(
                        oAuthConfigSettings.client_id_passwordless
                    );
                } catch (error) {
                    tokenRes = await performTokenRequest(
                        oAuthConfigSettings.client_id
                    );
                }
            } else {
                tokenRes = await performTokenRequest(
                    requestSettings.grant_type === TokenGrantType.GUEST
                        ? oAuthConfigSettings.client_id_passwordless
                        : oAuthConfigSettings.client_id
                );
            }

            if (tokenRes.accessToken) {
                // If Request is for Guest token, simply return the token
                if (requestSettings.grant_type === TokenGrantType.GUEST) {
                    return tokenRes;
                }

                const accessTokenExpiration =
                    parseJwt(tokenRes.accessToken).exp * 1000;

                await storeToken({
                    accessTokenExpiration,
                    ...tokenRes
                });
            }

            return tokenRes;
        }
    } catch (error) {
        console.error(error);
        datadogLogs.logger.log(
            'Unable to make token request',
            { error },
            'error'
        );
    }
};

export const arrangeMagicLink: (
    email: string
) => Promise<{ status?: number; error?: string }> = async email => {
    let res: Response;

    try {
        res = await Fetch({
            body: JSON.stringify({
                client_id: yumOidcClientIdPasswordless,
                identifier: email,
                metadata: {
                    source: 'WEB'
                }
            }),
            headers: { 'content-type': 'application/json' },
            host: webOrigin,
            method: 'POST',
            url: arrangeMagicLinkUrl
        });
    } catch (error) {
        console.error('Arrange magic link request failed!', error);
        datadogLogs.logger.log('arrangeMagicLink', { error }, 'error');
        return { error: 'Arrange magic link request failed!' };
    }

    if (!res.ok) {
        datadogLogs.logger.log('arrangeMagicLink', res, 'error');
    }

    return { status: res.status };
};

export const authRequest = async ({
    credential,
    email,
    ...form
}: AuthRequestProps) => {
    let res: Response;

    try {
        res = await Fetch({
            body: JSON.stringify({
                client_id: yumOidcClientIdPasswordless,
                credential,
                grant_type: 'client_credentials',
                identifier: email,
                ...form
            }),
            headers: { 'content-type': 'application/json' },
            host: '',
            method: 'POST',
            url: devProxyResolve(getCustomerUrl.replace(/v3|v2/, 'v4'))
        });
    } catch (error) {
        console.error('Auth request failed!', error);
        datadogLogs.logger.log('authRequest', { error }, 'error');
        return { success: false };
    }

    if (!res.ok) {
        datadogLogs.logger.log('authRequest', res, 'error');
        return { success: false, status: res.status };
    }

    return { success: true, ...(await res.json()) };
};
/**
 * Make an OAuth refresh token request.
 */
export const makeRefreshTokenRequest = async () => {
    await makeTokenRequest({
        grant_type: GRANT_TYPE_REFRESH_TOKEN,
        refresh_token: getDecodedCookie('refresh_token')
    });
};

/**
 * Make an OAuth guest token request.
 */
export const makeGuestTokenRequest = async () =>
    await makeTokenRequest({
        grant_type: TokenGrantType.GUEST
    });

export const storeToken = async ({
    accessToken,
    accessTokenExpiration,
    idToken,
    refreshToken
}: Pick<TokenResponse, 'accessToken' | 'idToken' | 'refreshToken'> & {
    accessTokenExpiration: number;
}) => {
    // Clear prior Auth cookie tokens before setting new ones
    await postDeleteAuthCookies();

    const sessionObject: RealObject = {
        access_token: accessToken,
        expiration: accessTokenExpiration,
        low_token: accessToken
    };
    if (idToken) {
        const idTokenExpiration = new Date(accessTokenExpiration);
        document.cookie = `id_token=${idToken}; expires=${idTokenExpiration.toUTCString()}; path=/`;
    }

    sessionStorage.setItem('token', JSON.stringify(sessionObject));

    if (refreshToken) {
        // Get time stamp 14 days from now for refresh token expiration
        const date = new Date();
        date.setDate(date.getDate() + 14);
        document.cookie = `refresh_token=${refreshToken}; expires=${date.toUTCString()}; path=/`;
    }
};
