import React, {
    ReactNode,
    useEffect,
    useMemo,
    useReducer,
    useState
} from 'react';

import { RealObject } from '@tb-core/types';

/* First we will make a new context */
const LayoutActivityContext = React.createContext<any>(null);
const { Consumer, Provider } = LayoutActivityContext;

interface LayoutActivityProviderProps {
    children: ReactNode;
}

const LayoutActivityProvider = ({ children }: LayoutActivityProviderProps) => {
    const [active, setActive] = useState(undefined);
    const [activeLoyalty, setActiveLoyalty] = useState(undefined);
    const [modals, updateModals] = useReducer(
        (state: RealObject<any>, newState: RealObject<any>) => ({
            ...state,
            ...newState
        }),
        {}
    );
    const ctx = useMemo(
        () => ({
            activateLeftAside: setActive,
            activateLoyalty: setActiveLoyalty,
            active,
            activeLoyalty,
            modals,
            updateModals
        }),
        [
            active,
            activeLoyalty,
            modals,
            setActive,
            setActiveLoyalty,
            updateModals
        ]
    );

    useEffect(() => {
        active || activeLoyalty
            ? document.body.classList.add('asideOpen')
            : document.body.classList.remove('asideOpen');
    }, [active, activeLoyalty]);

    return <Provider value={ctx}>{children}</Provider>;
};

const LayoutActivityConsumer = Consumer;

export default LayoutActivityProvider;
export { LayoutActivityContext, LayoutActivityConsumer };
