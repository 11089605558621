import classNames from 'classnames';
import React from 'react';

import { DivProps } from '@tb-core/types/elements/standard.d';

import styles from './styles.module.scss';

export type ModalProps = DivProps & {
    active: boolean;
};

const Modal = ({
    active = false,
    children,
    className,
    ...props
}: ModalProps) => (
    <>
        {active === true && (
            <div {...props} className={classNames(styles.modal, className)}>
                <div className={styles['modal-content']}>{children}</div>
            </div>
        )}
    </>
);

export default Modal;
