import 'isomorphic-unfetch';

import { checkoutApiTimeout } from '@tb-core/helpers/next-env';
import { isObject } from '@tb-core/helpers/object';
import { searchParams } from '@tb-core/helpers/url';
import { RealObject } from '@tb-core/types';

export type RequestMethod = 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT';

export interface FetchOptions extends RequestInit {
    body?: BodyInit;
    host?: string;
    method?: RequestMethod;
    payload?: RealObject;
    url: string;
    useDefaultHost?: boolean;
}

export default async function Fetch({
    body,
    credentials = 'include',
    headers = {},
    host = 'http://localhost:3000',
    method = 'GET',
    payload,
    url = '',
    useDefaultHost = true
}: FetchOptions) {
    const baseUrl = useDefaultHost ? host + url : url;
    const opts: RequestInit = {
        body: body || undefined,
        credentials,
        headers,
        method
    };

    const resource = payload ? searchParams(baseUrl, payload) : baseUrl;
    const res = await fetch(resource, opts);
    return res;
}

export async function FetchWithAbort({
    body,
    credentials = 'include',
    headers = {},
    host = 'http://localhost:3000',
    method = 'GET',
    payload,
    url = '',
    useDefaultHost = true
}: FetchOptions) {
    // Using AbortController() to cancel fetch, other option was Promise.race([])
    //  - when abort is triggered it will cause exception to be thrown
    //  - we already have try/catch around all Fetch calls so exception will be handled appropriately
    // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/AbortController
    // https://davidwalsh.name/cancel-fetch
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise#promise.raceiterable
    const controller = new AbortController();
    const { signal } = controller;
    const baseUrl = useDefaultHost ? host + url : url;
    const opts: RequestInit = {
        body: body || undefined,
        credentials,
        headers,
        method,
        signal
    };
    const resource = payload ? searchParams(baseUrl, payload) : baseUrl;
    const milliseconds = parseInt(checkoutApiTimeout, 10) * 1000;

    let timeout: ReturnType<typeof setTimeout> | undefined;
    let res: Response;

    if (!isNaN(milliseconds)) {
        // This will abort the fetch if after waiting X milliseconds call still not complete.
        // Note aborting causes: exception in below try/catch to be thrown
        timeout = setTimeout(() => controller.abort(), milliseconds);
    }
    try {
        res = await fetch(resource, opts);
    } catch (e) {
        // Uncomment in future if we want to display a non-generic error message for Forced Abort
        // if (e.name === 'AbortError') {
        //     e.errors = [ { message: '<non-generic error message here>' } ];
        // }
        // Currently generic error message is set inside payment-main :: handleSubmit() :: setErrrorMessage()
        throw e;
    } finally {
        if (timeout) {
            clearTimeout(timeout);
        }
    }
    return res;
}

/**
 * based on Type guards for try catch blocks by
 * https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
 */
export const toError = (maybeError: unknown): RealObject => {
    if (isObject(maybeError)) {
        return maybeError;
    }
    try {
        return new Error(JSON.stringify(maybeError));
    } catch {
        return new Error(String(maybeError));
    }
};
