import { GetServerSidePropsContext } from 'next';
import React, { ReactNode } from 'react';

import { PageDataProps } from '@tb-core/adapters/contentful/graphql/webpage-adapter';
import { Store } from '@tb-core/types';

export interface PageContent {
    pageData: Omit<PageDataProps, 'topicMetadataCollection'>;
}

export interface PageContextProps {
    content: PageContent;
    ctx: Partial<Omit<GetServerSidePropsContext, 'req' | 'res'>> & {
        url: string;
    };
    notFound?: boolean;
    storeData?: Store;
}

export interface PageProviderProps extends PageContextProps {
    children: ReactNode;
}

/* First we will make a new context */
const PageContext = React.createContext<PageContextProps | undefined>(
    undefined
);
const { Provider } = PageContext;

const PageProvider = ({ children, ...props }: PageProviderProps) => (
    <Provider value={props}>{children}</Provider>
);

export default PageProvider;
export { PageContext };
