import classNames from 'classnames';
import { KeyboardEventHandler, ReactNode, useEffect } from 'react';

import Modal from '@tb-core/components/composites/modal';
import LazyIconClose from '@tb-core/components/simple/icons/lazy-icon-close';
import Button from '@tb-core/components/styled/buttons/button';
import {
    GaDataLayerConfig,
    updateDataLayer
} from '@tb-core/helpers/analytics/google';
import { keepFocusInElement } from '@tb-core/helpers/utils/keep-focus-in-element';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';

import styles from './styles.module.scss';

export interface StandardModalProps {
    ariaDescribedby?: string;
    ariaLabelledby?: string;
    body: ReactNode;
    className?: string;
    contentStyle?: string;
    footer?: ReactNode;
    gaDataLayerConfig?: GaDataLayerConfig;
    hideClose?: boolean;
    id: string;
    onClose?: () => void;
    role?: string;
    showHeader?: boolean;
    title?: ReactNode;
}

const StandardModal = ({
    ariaDescribedby,
    ariaLabelledby,
    body,
    className,
    contentStyle = '',
    footer,
    gaDataLayerConfig,
    hideClose,
    id,
    onClose,
    role = 'dialog',
    showHeader = true,
    title
}: StandardModalProps) => {
    const { modals, updateModals } = useLayoutActivityContext();

    const onClickClose = () => {
        updateModals({ [id]: undefined });
        if (onClose) {
            onClose();
        }
    };

    useEffect(() => {
        if (modals[id] && gaDataLayerConfig) {
            updateDataLayer(gaDataLayerConfig);
        }
    }, [modals]);

    const keyDownHandler: KeyboardEventHandler = event => {
        keepFocusInElement({ event, id });
    };

    return (
        <Modal
            active={modals[id] !== undefined}
            aria-describedby={ariaDescribedby}
            aria-labelledby={ariaLabelledby}
            aria-modal={true}
            className={classNames(styles['standard-modal'], className)}
            id={id}
            onKeyDown={keyDownHandler}
            role={role}
        >
            {showHeader && (
                <header>
                    {title}
                    {hideClose !== true && (
                        <Button
                            aria-label="Close"
                            autoFocus={true}
                            className={styles.close}
                            onClick={onClickClose}
                            tabIndex={0}
                        >
                            <LazyIconClose size="1em" />
                        </Button>
                    )}
                </header>
            )}
            <div className={`${styles.body}${' '}${contentStyle}`}>{body}</div>
            {footer && <footer>{footer}</footer>}
        </Modal>
    );
};

export default StandardModal;
