import { RealObject } from '@tb-core/types';

export function searchParams(url: string, query: RealObject) {
    if (Object.keys(query).length) {
        url += '?';

        for (const [key, value] of Object.entries(query)) {
            url = `${url}${
                url.lastIndexOf('?') === url.length - 1 ? '' : '&'
            }${key}=${value}`;
        }
    }

    return url;
}

/**
 * Parses URLSearchParams from string to object literal.
 * @param urlSearchParams A url with search params
 */
export function query(urlSearchParams: string = ''): RealObject<string> {
    return (
        urlSearchParams
            .split('?')[1]
            ?.split('&')
            .reduce((acc: RealObject, pair) => {
                const [key, value, ...rest] = pair.split('=');

                acc[key] = [value, ...rest].join('=');
                return acc;
            }, {}) || {}
    );
}

/**
 * create a string of name-value pairs to be appended to a url string. Includes only values that are defined
 * @param params
 * @returns a query string to be appended to a url
 */
export function createQueryString(params: RealObject) {
    let parameters = '';

    for (const key in params) {
        if (typeof params[key] !== 'undefined') {
            parameters = parameters.length
                ? parameters + `&${key}=${params[key]}`
                : `?${key}=${params[key]}`;
        }
    }

    return parameters;
}
