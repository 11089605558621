import { isPickupTimeInFuture } from '@tb-core/helpers/date';
import { GetSelectedStore, GetStoreData } from '@tb-core/helpers/storage';
import { TimeMethod } from '@tb-core/types/location.d';

/**
 * Find the store id value within a given lookup string
 * @param lookup the lookup string
 * @return storeId string or undefined
 */
export const getQueryStoreId = (lookup = ''): string | undefined =>
    (lookup.match(/(?:\?|&)store=([0-9]{6})/) || [])[1];

/**
 * Verifies if a store id is a valid one
 * @param storeId store id string to test
 * @return boolean
 */
export const isValidStoreId = (storeId: string) => /[0-9]{6}/.test(storeId);

/**
 * @return
 * - undefined - server side rendered
 * - true - if user has already choosen a store
 * - false - if user is currently seeing National Store Data \
 */
export const isStoreSelected = () => {
    const storeData = GetStoreData();
    const selectedStore = GetSelectedStore();

    if (
        storeData &&
        selectedStore &&
        storeData.storeId === selectedStore.storeId &&
        (selectedStore.storePickupTimeMethod === TimeMethod.Now ||
            isPickupTimeInFuture(selectedStore.pickupTimeStamp))
    ) {
        return true;
    }
    return false;
};
