import { CustomizationOption, VariantOption } from '@tb-core/types/products';

/**
 * This function aggregrates all of the different protein variant options into
 * a single array.
 *
 * @param proteinOptions - contains the protein options (ex: Seasoned Beef, Chicken, etc)
 * @return variantOptions - contains the variant options for the different protein options
 * (ex: NO Seasoned Beef, Extra Seasoned Beef)
 */
const ProteinOptionsAdapter = (proteinOptions: CustomizationOption[]) => {
    const variantOptions: VariantOption[] = [];

    proteinOptions?.forEach(proteinOption => {
        for (const variantOption of proteinOption.variantOptions) {
            variantOptions.push(variantOption);
        }
    });

    return variantOptions;
};

export default ProteinOptionsAdapter;
