import SafeAnchor, {
    SafeAnchorProps
} from '@tb-core/components/behavior/safe-anchor';
import {
    GaDataLayerConfig,
    gtmLinkClick
} from '@tb-core/helpers/analytics/google';

export interface SafeAnchorWithGaEventProps extends SafeAnchorProps {
    gaDataLayerConfig?: GaDataLayerConfig;
}

const SafeAnchorWithGaEvent = ({
    gaDataLayerConfig = {},
    onClick,
    ...props
}: SafeAnchorWithGaEventProps) => {
    const handleClick = (e: any) => {
        gtmLinkClick(gaDataLayerConfig);

        if (onClick) {
            onClick(e);
        }
    };

    return <SafeAnchor {...props} onClick={handleClick} />;
};

export default SafeAnchorWithGaEvent;
