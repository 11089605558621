import { createContext, useEffect, useState } from 'react';

import { getAccessToken } from '@tb-core/helpers/client/auth';
import sessionStorage from '@tb-core/helpers/storage';
import { getUsersData } from '@tb-core/providers/get-users-data';
import { postDeleteAuthCookies } from '@tb-core/providers/post-delete-auth-cookies';
import {
    AnyUser,
    GuestUser,
    InitializingUser,
    JsxChildren,
    User
} from '@tb-core/types';

interface UserProviderProps {
    children: JsxChildren;
    // This will indicate if we need to fetch the user data
    enabled?: boolean;
}

const initializingUser: InitializingUser & OnLogout = {
    isLoggedIn: undefined,
    onLogout: () => {},
    userUid: undefined
};

const guestUser: GuestUser = {
    isLoggedIn: false,
    userUid: ''
};

interface OnLogout {
    onLogout: () => void;
}

const UserContext = createContext<AnyUser & OnLogout>(initializingUser);
const { Consumer, Provider } = UserContext;

const UserProvider = ({ children, enabled = true }: UserProviderProps) => {
    const [user, setUser] = useState<AnyUser>(initializingUser);

    const updateUser = (user: User) => {
        sessionStorage.setItems({ user });
        setUser(user);
    };

    const clearPriorUser = () => {
        window.sessionStorage.removeItem('token');
        postDeleteAuthCookies();
        updateUser(guestUser);
    };

    const initUser = async () => {
        if (enabled) {
            const accessToken = await getAccessToken();
            if (accessToken) {
                const userData = await getUsersData();
                if (userData.success && userData.isLoggedIn) {
                    updateUser(userData);
                    return;
                }
            }
        }
        clearPriorUser();
    };

    useEffect(() => {
        initUser();
    }, []);

    return (
        <Provider value={{ ...user, onLogout: clearPriorUser }}>
            {children}
        </Provider>
    );
};

const UserConsumer = Consumer;

export default UserProvider;
export { UserConsumer, UserContext };
