import { getDecodedCookie } from '@tb-core/helpers/cookies';
import { getDocumentCookie } from '@tb-core/helpers/document-cookie';
import sessionStorage from '@tb-core/helpers/storage';
import {
    makeGuestTokenRequest,
    makeRefreshTokenRequest
} from '@tb-core/providers/auth/make-token-request';
import { RealObject } from '@tb-core/types';

/**
 * Returns users jwt access token
 * @return string | null
 */
export const getAccessToken = async () => await getToken('access_token');

/**
 * Returns users refresh token
 * @return string | null
 */
export const getRefreshToken = () => getDecodedCookie('refresh_token');

/**
 * Returns user's JWT access token. If not present,
 * or if access token is expired, attempt to refresh the
 * access token using refresh_token.
 *
 * If no refresh_token is present, it is assumed the user is
 * logged out, so return nothing.
 *
 * @return string | null
 */
export const retrieveYumToken = async (tokenName: string) => {
    let token = sessionStorage.getItems().token;
    const refreshToken = getRefreshToken();
    if (refreshToken) {
        if (!token) {
            await makeRefreshTokenRequest();
            token = sessionStorage.getItems().token;
        } else if (Date.now() >= token.expiration) {
            await makeRefreshTokenRequest();
            token = sessionStorage.getItems().token;
        }
        return token?.[tokenName] as string;
    }
    return null;
};

/**
 * Returns users CSRFToken
 * @return string | null
 */
export const getCSRFToken = () => getDocumentCookie('CSRFToken');

/**
 * Returns users jwt id token
 * @return string | null
 */
export const getIdToken = () => getDecodedCookie('id_token');

/**
 * Returns users jwt loyalty on the web token
 * @return string | null
 */
export const getLowToken = async () => await getToken('low_token');

/**
 * Helper function to retrieve the Yum Auth token
 * @return string | null
 */
const getToken = async (tokenName: string) => {
    return await retrieveYumToken(tokenName);
};

/**
 * Helper function to retrieve the Yum Auth Guest token
 * @return string | null
 */
export const getGuestToken = async () => {
    const tokenRes = await makeGuestTokenRequest();
    return tokenRes?.accessToken;
};

/**
 * Adds access token bearer header jwt to http headers
 */
export const setAccessTokenHeader = async (headers: RealObject = {}) => {
    const accessToken = await getAccessToken();

    setBearerTokenHeader(headers, accessToken);

    return headers;
};

/**
 * Adds csrf token header to http headers
 */
export const setCSRFTokenHeader = (headers: RealObject = {}) => {
    const CSRFToken = getCSRFToken();

    if (CSRFToken) {
        headers.CSRFToken = CSRFToken;
    }

    return headers;
};

/**
 * Adds id token bearer header jwt to http headers
 */
export const setIdTokenHeader = async (headers: RealObject = {}) => {
    const idToken = getIdToken();

    setBearerTokenHeader(headers, idToken);

    return headers;
};

/**
 * Adds loyalty on the web bearer header jwt to http headers
 */
export const setLowTokenHeader = async (headers: RealObject = {}) => {
    const lowToken = await getLowToken();

    setBearerTokenHeader(headers, lowToken);

    return headers;
};

export const setBearerTokenHeader = (
    headers: RealObject = {},
    token: string | null
) => {
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return headers;
};

/**
 * Function to parse a JWT access token, and return a JSON
 *
 * @param token - The access token to parse
 * @returns A JSON of the access token
 */
export const parseJwt = (token: string) => {
    try {
        return JSON.parse(
            Buffer.from(token.split('.')[1], 'base64').toString()
        );
    } catch (e) {
        return {};
    }
};
