import { v4 as uuid } from 'uuid';

import { HybrisStore, RealObject, Store, User } from '@tb-core/types';
import { FavoriteProduct } from '@tb-core/types/favorites';
import { SelectedStore } from '@tb-core/types/stores';

// used by both sessionStorage and localStorage
const reduceFunction = (acc: RealObject, [key, value]: [string, string]) => {
    value = value.trim();

    try {
        acc[key] =
            // JSON test
            /^{.*}$/.test(value)
                ? JSON.parse(value)
                : // Number test
                /^\d$/.test(value)
                ? Number(value)
                : // Truthy test
                value === 'true'
                ? true
                : // Falsy test
                value === 'false'
                ? false
                : // Use raw value.
                  value;
    } catch (e) {
        throw Error(e as string | undefined);
    }

    return acc;
};

const clientSideGetterAndSetter = (storage: Storage) => {
    return {
        /**
         * Gets & returns ALL sessionStorage items as fully parsed out values ready for consumption.
         */
        getItems: () => {
            if (typeof window === 'undefined') {
                return {};
            }
            // Parse each entry.
            return Object.entries(storage).reduce(reduceFunction, {});
        },

        /**
         * Iterates items to set each one to sessionStorage.
         * @param items An object containing entries as key/value pairs.
         * @param convertToString Boolean to determine whether to JSON.stringify the stored value.
         *                        Should be used when storing a String value.
         */
        setItems: (items: RealObject, convertToString: boolean = true) => {
            Object.entries(items).forEach(([key, value]) =>
                storage.setItem(
                    key,
                    convertToString ? JSON.stringify(value) : value
                )
            );
        }
    };
};

// Because without this Next.js server side execution says: WTF is a 'window'?
const serverSidePlaceHolder = {
    getItems: () => {
        return {} as RealObject<any>;
    },
    // TODO - see ECORD-2629 to fix
    // items and convertToString declared but never read
    // @ts-ignore
    setItems: (items: RealObject, convertToString: boolean = true) => {}
};

/** sessionStorage that doesn't persist (between tabs or browser restarts) */
const sessionStorage =
    typeof window !== 'undefined'
        ? clientSideGetterAndSetter(window.sessionStorage)
        : serverSidePlaceHolder;

/** localStorage that persists (between tabs and browser restarts) ... but no PII data! */
export const localStorage =
    typeof window !== 'undefined'
        ? clientSideGetterAndSetter(window.localStorage)
        : serverSidePlaceHolder;

// I recomend defining all getter functions here so the return type is consistant

/** @deprecated -
 * will be deleted when PickupMethod / PickupTimeMethod selection moved \
 * to Checkout Page \
 * from Location Page \
 * GetStoreData - is an alternative, but doesn't have same info.
 */
export const GetSelectedStore = (): SelectedStore | undefined => {
    return localStorage.getItems().selectedStore;
};

/** 'storeData' is saved to Local Storage by both Hybris and Web 2.0 pages \
 * when store url param is detected: `?store=######` \
 * Hybris Page Example: https://www.tacobell.com/gift-cards?store=039806 \
 * Web 2.0 Page Example: https://www.tacobell.com/food?store=039806 \
 * Web 2.0 Pages get this data from getStore(#######) --> storeAdapter() \
 * Hybris Pages get this data directly from /store-finder/store?storeID=###### \
 * if Hybris receives an invalid storeId it stores the storeId + all null values \
 * if Web 2.0 receives an invalid storeId it does not store the store at all
 */
export const GetStoreData = () => {
    const store: Store | undefined = localStorage.getItems().storeData;
    const isStoreDataValid = () => {
        const isNullHybrisStore = store
            ? (store as HybrisStore).dayparts === null &&
              (store as HybrisStore).openingScheduleData === null &&
              (store as HybrisStore).storeName === null
            : false;
        return store?.storeId && !isNullHybrisStore;
    };
    return isStoreDataValid() ? store : undefined;
};

export const GetUser = (): User | undefined => {
    return sessionStorage.getItems().user;
};

export const GetAndSetDeviceId = (): string => {
    const storedDeviceId = localStorage.getItems().deviceId;

    if (storedDeviceId) {
        return JSON.parse(storedDeviceId);
    }

    const newDeviceId = uuid();

    localStorage.setItems({
        deviceId: newDeviceId
    });

    return newDeviceId;
};

export const userGoingToCart = (): boolean | undefined => {
    return sessionStorage.getItems().returnToCart;
};

export const clearUserGoingToCart = () => {
    window.sessionStorage.removeItem('returnToCart');
};

export const optimizelyGuestUserId = (): string | undefined => {
    return localStorage.getItems().optimizelyGuestUserId;
};

export const saveUsersPromoCode = (usersPromoCode?: string) => {
    if (usersPromoCode === undefined) {
        window.sessionStorage.removeItem('usersPromoCode');
    } else {
        sessionStorage.setItems({ usersPromoCode }, false);
    }
};

export const retrieveUsersPromoCode = (): string | undefined => {
    return sessionStorage.getItems().usersPromoCode;
};

export const getSessionFavorites: () => FavoriteProduct[] = () => {
    const webFavorites = sessionStorage.getItems().webFavorites;

    return webFavorites ? JSON.parse(webFavorites) : [];
};

export const setSessionFavorites = (favorites: FavoriteProduct[]) => {
    return sessionStorage.setItems({ webFavorites: favorites });
};

export default sessionStorage;
