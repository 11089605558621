import { datadogLogs } from '@datadog/browser-logs';
import { devProxyResolve } from '@tb-core/helpers/browser/dev-proxy-resolve';
import { getAccessToken } from '@tb-core/helpers/client/auth';
import Fetch from '@tb-core/helpers/fetch';
import { setProviderUrl } from '@tb-core/helpers/utils/optimizely/set-provider-url';
import { getCustomerUrl } from '@tb-core/next/api/urls';
import { FetchResponse, User } from '@tb-core/types';

export async function getUsersData(): FetchResponse<User> {
    let res: Response;
    const url = devProxyResolve(setProviderUrl(getCustomerUrl));
    const accessToken = await getAccessToken();

    try {
        res = await Fetch({
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            host: '',
            url
        });
    } catch (error) {
        console.error('getUsersData Failed', error);
        datadogLogs.logger.log('getUsersData Failed', { error }, 'error');
        return { success: false };
    }

    if (!res.ok) {
        const errors = await res.json();
        datadogLogs.logger.log('getUsersData Failed', errors, 'error');
        return { success: false };
    }

    const { uid, offersCount, ordersCount, ...user } = await res.json();
    return {
        ...user,
        isLoggedIn: !!uid,
        offersCount: Number(offersCount) || 0,
        ordersCount: Number(ordersCount) || 0,
        success: true,
        userUid: uid
    };
}
