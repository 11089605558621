import { storeAdapter } from '@tb-core/adapters/store/store-adapter';
import { devProxyResolve } from '@tb-core/helpers/browser/dev-proxy-resolve';
import Fetch from '@tb-core/helpers/fetch';
import { setProviderUrl } from '@tb-core/helpers/utils/optimizely/set-provider-url';
import { nextStoreUrl } from '@tb-core/next/api/urls';
import { FetchResponse, Store } from '@tb-core/types';

export default async function getStore(
    storeId: number | string
): FetchResponse<Store> {
    let res: Response;
    const url = devProxyResolve(setProviderUrl(nextStoreUrl), { storeId });

    try {
        res = await Fetch({
            host: '',
            url
        });
    } catch (e) {
        console.error('Store request failed!', e);
        return { success: false };
    }

    if (!res.ok) {
        console.error(
            'Store request failed! Reason:',
            res.status,
            res.statusText
        );
        return { success: false };
    }

    return {
        ...storeAdapter(await res.json()),
        success: true
    };
}
