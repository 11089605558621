import { TimeMethod } from '@tb-core/types/location.d';
import { StoreTime } from '@tb-core/types/stores';
import { GetSelectedStore } from './storage';

export const todaysDate = () => {
    return new Date().toLocaleString('en-US', {
        day: 'numeric',
        month: 'long',
        weekday: 'long'
    });
};

export const isPickupTimeInFuture = (timestamp: number) => {
    return timestamp - new Date().getTime() > 60000; // at least 1 minute in future
};

// getTimezoneOffsetAsMillis()
//                       sign *    minutes   * millis per minute
// input: "GMT+05:30" ->    1 * ( 5.5 * 60 ) * ( 1000 * 60 ) -> output: 19800000
// input: "GMT-07:00" ->   -1 * ( 7.0 * 60 ) * ( 1000 * 60 ) -> output: -25200000
// input: "GMT"       ->    1 * ( 0.0 * 60 ) * ( 1000 * 60 ) -> output: 0
const getTimezoneOffsetAsMillis = (timezoneOffset: string) => {
    const sign = timezoneOffset.includes('-') ? -1 : 1;
    const [, hoursString, minutesString] = timezoneOffset.split(/[+\-:]/);
    const hours = hoursString ? parseInt(hoursString, 10) : 0;
    const minutes = minutesString ? parseInt(minutesString, 10) : 0;
    const millisPerMinute = 60000; // 1000 * 60
    return sign * (hours * 60 + minutes) * millisPerMinute;
};

/** getMinutesAdjustedForTimezone()
 * javascript Date is automatically adjusted for users timezone, we don't want that, we want to ignore user timezone.
 * We will do the timezone offset adjustment for store timezone ourselves then grab UTC again ignoring user timezone.
 */
const getMinutesAdjustedForTimezone = (timestamp: number, timezone: string) => {
    const millis = timestamp + getTimezoneOffsetAsMillis(timezone);
    const pickupTime = new Date(millis);
    const minutes = pickupTime.getUTCMinutes();
    const hours = pickupTime.getUTCHours();
    return hours * 60 + minutes;
};

const convertStoreTimeToMinutes = (storeTime: StoreTime) => {
    const minutesPMOffset = storeTime.formattedHour.includes('PM') ? 720 : 0; // 720 = 60 * 12
    const minutes = storeTime.hour * 60 + storeTime.minute + minutesPMOffset;
    return minutes;
};

/** isBreakfastAvailable()
 * If we had breakfast start time / end time as millis since epoch
 * this would be 1 line of code, not needing all this converting logic
 * TODO: bug hybris guys / Apps guys even more to see if there is
 *       millis since epoch breakfast start/end time
 *  @return true | false | undefined
 */
export const isBreakfastAvailable = () => {
    const selectedStore = GetSelectedStore();

    if (!selectedStore) {
        // No Store Selected Yet
        return undefined;
    }
    const { breakfast, storePickupTimeMethod, storeTimeZone } = selectedStore;
    if (
        breakfast?.isThereBreakfastToday &&
        storePickupTimeMethod &&
        storeTimeZone
    ) {
        const { isAllDay, startTime, endTime } = breakfast;
        // Breakfast is all day
        if (isAllDay) {
            return true;
        }

        const isTimeMethodLater = storePickupTimeMethod === TimeMethod.Later;
        const timestamp = isTimeMethodLater
            ? selectedStore.pickupTimeStamp
            : Date.now();
        if (timestamp) {
            const pickupMinutes = getMinutesAdjustedForTimezone(
                timestamp,
                storeTimeZone
            );
            const startMinutes = convertStoreTimeToMinutes(startTime);
            const endMinutes = convertStoreTimeToMinutes(endTime);
            const isBreakfastTime =
                pickupMinutes >= startMinutes - 14 && // allow breakfast order up to 14min before breakfast start
                pickupMinutes < endMinutes;

            // Breakfast is being served at the time of pickup / now
            if (isBreakfastTime) {
                return true;
            }
        }
    }
    // No Breakfast at time of pickup / now
    return false;
};

/**
 * Adds a provided number of hours to a provided timestamp.
 * Useful for date comparisons in the future.
 * Timestamp is stored as Seconds, so multiplied by 1000 to receive Milliseconds.
 * numHours x 60 Minutes in an Hour x 60 Seconds in a Minute x 1000 Milliseconds in a Second
 *
 * @param timestamp - The timestamp to add the hours to
 * @param numHours - The number of hours to add to the timestamp
 */
export const addHoursToDate = (timestamp: number, numHours: number): number =>
    timestamp * 1000 + numHours * 60 * 60 * 1000;
