export enum CSCItemModifierOptions {
    ADDON = 'addonOptions',
    FRESCO_ADD = 'frescoAdd',
    FRESCO_PRODUCT = 'frescoProduct',
    FRESCO_REMOVE = 'frescoRemove',
    GRILLABLE_PRODUCT = 'grillableProduct',
    INCLUDE = 'includeOptions',
    // to use for the initialization state of the useCustomize hook, this will be the default
    // value when an option is not selected
    NOT_SELECTED = 'notSelected',
    PROTEIN = 'proteinOptions',
    SAUCE = 'sauceOptions',
    SHELL = 'shellOptions',
    SUPREME_ADD = 'supremeAdd',
    SUPREME_PRODUCT = 'supremeProduct',
    SUPREME_REMOVE = 'supremeRemove',
    UPGRADE = 'upgradeOptions',
    VEGETARIAN_ADD = 'vegetarianAdd',
    VEGETARIAN_PRODUCT = 'vegetarianProduct',
    VEGETARIAN_REMOVE = 'vegetarianRemove'
}

export enum CSCItemModifierType {
    ADD = 'ADD',
    EASY = 'EASY',
    EXTRA = 'EXTRA',
    MINUS = 'MINUS',
    SIDE = 'SIDE',
    STYLE = 'STYLE'
}
