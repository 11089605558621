import {
    AuthorizationServiceConfiguration,
    FetchRequestor
} from '@openid/appauth';

import {
    webOrigin,
    yumOidcClientId,
    yumOidcClientIdPasswordless,
    yumOidcClientName,
    yumOidcEnvironment
} from '@tb-core/helpers/next-env';
import { yumOidcAuthUrl } from '@tb-core/next/api/urls';

export const oAuthConfigSettings = {
    authority: webOrigin + yumOidcAuthUrl,
    client_id: yumOidcClientId,
    client_id_passwordless: yumOidcClientIdPasswordless,
    client_name: yumOidcClientName,
    environment: yumOidcEnvironment,
    response_type: 'code',
    scope: 'openid offline'
};

/**
 * Fetch OpenId config settings.
 *
 * @return OpenId config settings specific to provided authority.
 */
export const fetchAuthConfig = async () =>
    await AuthorizationServiceConfiguration.fetchFromIssuer(
        oAuthConfigSettings.authority,
        new FetchRequestor()
    );
