import { RealObject } from '@tb-core/types';

export const filterUndefinedObjKey = (obj: RealObject) =>
    Object.entries(obj).filter(([key]) => key !== 'undefined');

export const isEmptyObject = (obj: any): boolean => {
    for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
            return false;
        }
    }
    return true;
};

export const isObject = (error: unknown): error is {} =>
    typeof error === 'object' && error !== null && !Array.isArray(error);
