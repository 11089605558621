import { RealObject } from '@tb-core/types';

/**
 * Server-side only.
 * Iteratively parses cookies as a proper cookie string for a Request Header.Cookie.
 * @param cookies An object containing entries as key/value pairs.
 */
export function toCookieString(cookies: RealObject) {
    return Object.entries(cookies).reduce(
        (acc, [key, value]) => `${acc}${key}=${value}; `,
        ''
    );
}

/**
 * Returns the value of the desired cookie from the cookie string, or null.
 * @param key The name of the cookie.
 * @param cookieString A valid cookie string used for the cookie lookup.
 */
export function getCookie(key: string, cookieString: string = '') {
    const cookieRe = RegExp(`(?:;\\s|;|^)${key}=([{}a-zA-Z0-9-%:.|=()]+)`);
    const match = cookieString.match(cookieRe);

    return match && match[1];
}

export function deleteCookie(key: string) {
    document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
}

export function getDecodedCookie(cookieName: string) {
    if (typeof window !== 'undefined') {
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');

        for (let cookie of cookieArray) {
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
    }

    return '';
}
