import { useContext } from 'react';

import { PageContext } from '@tb-core/components/context/webpage';

const usePageContext = () => {
    const ctx = useContext(PageContext);

    if (ctx === undefined) {
        throw new Error('usePageContext must be used within a PageProvider');
    }

    return ctx;
};

export default usePageContext;
