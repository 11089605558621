import { interpolate } from '@tb-core/helpers/interpolate';
import { devProxyUrl } from '@tb-core/next/api/urls';

/**
 * Use to resolve the target url to point to the development proxy endpoint, Next API route `/api/dev-proxy/[url]`,
 * when on localhost. Solves issues with CORS.
 * DO NOT USE when the original target is already a Next API route.
 * @param url The target URL to proxy.
 * @param urlValues A `RealObject` used for url dynamic interpolation, i.e '/some-target-url?key={value}'
 */
export const devProxyResolve = (url = '', urlValues = {}) => {
    const isLocal = /localhost/.test(window?.location.hostname);
    const interpolatedUrl = interpolate(url.slice(1), urlValues);
    const resolvedUrl = `/${
        // Encode the URL only for local development
        // to reliably pass the URL to the dev proxy Next API route.
        isLocal ? encodeURIComponent(interpolatedUrl) : interpolatedUrl
    }`;

    return isLocal
        ? interpolate(devProxyUrl, { url: resolvedUrl })
        : resolvedUrl;
};

/**
 * Use to resolve the target url to point to the development server side localhost
 * @param serverSideHost Server Side Host
 * @param url The target URL to proxy.
 * @param urlValues A `RealObject` used for url dynamic interpolation, i.e '/some-target-url?key={value}'
 */
export const devSSRProxyResolve = (
    serverSideHost = '',
    url = '',
    urlValues = {}
) => {
    const isLocal = serverSideHost ? /localhost/.test(serverSideHost) : false;
    const hostPrefix = isLocal ? 'http://' : 'https://';
    const data = urlValues;
    const productUrl = interpolate(url[0] === '/' ? url.slice(1) : url, data);
    const path = interpolate(devProxyUrl, {
        url: `/${isLocal ? encodeURIComponent(productUrl) : productUrl}`
    });
    return serverSideHost
        ? hostPrefix + serverSideHost + path
        : devProxyResolve(url, data);
};
